module.exports = [{
      plugin: require('/Users/benjiegillam/Dev/learn.graphile.org/node_modules/gatsby-mdx/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/benjiegillam/Dev/learn.graphile.org/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-104087300-2","head":false,"anonymize":true,"respectDNT":false,"cookieDomain":"learn.graphile.org"},
    },{
      plugin: require('/Users/benjiegillam/Dev/learn.graphile.org/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
